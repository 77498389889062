import React from 'react';
import { Container, Row, Col, Image, Anchor } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useMediaCustom from "../../Hooks/useMediaCustom"

export default function Footer() {

    const isMobile = useMediaCustom('(max-width: 600px)')

    return (
        <Container fluid="xs" className="px-0 bg-footer bg-footer-image">
            <Row className="text-center  mx-0 border-tp py-4">
                <Col xs={2} className="mx-auto">
                    <Link to="/">
                        <Image className="w-50"  src='../image/logo.png' alt="Лого на ООУ „Ѓорче Петров“ " />
                    </Link>
                </Col>
            </Row>
            {!isMobile && <Row className='mx-0 pb-4'>
                <Col xs={12} lg={6} className="footer-link mb-3 text-end">
                    <Link to="/za-nas">За Нас</Link>
                    <Link className="ms-5" to="/sekcii">Секции</Link>
                    <Link className="ms-5" to="/aktivnosti">Вон-наставни активности</Link>
                </Col>
                <Col xs={12} lg={6} className="footer-link text-start">
                    <Anchor>Ученички Парламент</Anchor>
                    <Link className="ms-5" to="/soopshtenija">Соопштенија</Link>
                    <Link className="ms-5" to="/vraboteni">Вработени</Link>
                    <Link className="ms-5" to="/kontakt">Контакт</Link>
                </Col>
            </Row>}
            <Row className="text-center mx-0 pb-4">
                <Col xs={12} className="footer-link">
                    <Anchor href="https://www.facebook.com/oou.gjorce.petrov.skopje" target='_blank'><i className="fab fa-facebook-f"></i></Anchor>
                    <Anchor className="ms-3" href="https://www.facebook.com/oou.gjorce.petrov.skopje" target='_blank'><i className="fab fa-facebook-messenger"></i></Anchor>
                    <Link className="ms-3" to="/"><i className="fab fa-instagram"></i></Link>
                    <Link className="ms-3" to="/"><i className="fab fa-linkedin-in"></i></Link>
                </Col>
            </Row>
            <Row className="text-center mx-0">
                <Col xs={12} lg={{ span: 2, offset: 4 }} className="footer-link d-flex flex-row justify-content-center justify-content-lg-end">
                    <p className='me-2'><span className='me-2'><i className="fa fa-road"></i></span>ул. Борис Сарафов бр.131, Скопје</p>
                </Col>
                <Col xs={12} lg={2} className="footer-link d-flex flex-row justify-content-center justify-content-lg-start">
                    <p className='me-2'><span className='me-2'><i className="fa fa-phone"></i></span>02/2037-055, 02/2036-037</p>
                </Col>
            </Row>
            <Row className="text-center mx-0 pb-4">
                <Col xs={12} className="footer-link">
                    <p>&copy; Copyright 2022 | All Rights reserved | Vega Design </p>
                </Col>
            </Row>
        </Container>
    );
}
