import React from 'react';
import {Col, Card} from 'react-bootstrap'
import classes from './announcementcard.module.css'
import { API } from '../../consts/API';
import { Link } from 'react-router-dom';

const Announcementcard = ({cover_image, title, sid, route}) => {
  return (
    <Col as={Link} to={`/${route}/${sid}`} xs={12} md={6} lg={4} className={classes.announceCard}>
      <Card>
        <Card.Img variant='start' src={API.STORAGE+cover_image} />
        <Card.Body className='p-0'>
          <Card.Title>
            {title}
          </Card.Title>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Announcementcard;
