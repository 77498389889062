// import logo from './logo.png';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Announcements from './pages/Announcements/Announcements';
import Employees from './pages/Employees/Employees'
import Contact from './pages/Contact/Contact'
import Notfound from './pages/Notfound/Notfound';
import Footer from './components/Footer/Footer';
import AboutUsPage from './pages/AboutUs/AboutUsPage';
import Sekcii from './pages/Sekcii/Sekcii';
import VonnastavniAktivnosti from './pages/VonNastavniAktivnosti/VonnastavniAktivnosti';
import { DatacontextProvider } from './context/Datacontext';
import Detailspage from './components/Detailspage/Detailspage';

function App() {
  return (
    <DatacontextProvider>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/za-nas' element={<AboutUsPage />} />
          <Route path='/sekcii' element={<Sekcii />} />
          <Route path='/aktivnosti' element={<VonnastavniAktivnosti />} />
          <Route path='/soopshtenija' element={<Announcements />} />
          <Route path='/vraboteni' element={<Employees />} />
          <Route path='/kontakt' element={<Contact />} />
          <Route path='/soopshtenija/:id' element={<Detailspage />} />
          <Route path='/sekcii/:id' element={<Detailspage />} />
          <Route path='/aktivnosti/:id' element={<Detailspage />} />
          <Route path='*' element={<Notfound />} />
        </Routes>
        <Footer/>
      </Router>
    </DatacontextProvider>
  );
}

export default App;
