const baseUrl = process.env.REACT_APP_API_URL;
const storageUrl = process.env.REACT_APP_STORAGE_URL;

const dynamicRoute = route => baseUrl + route

export const API = {
  BASE: baseUrl,
  dynamicRoute,
  GET: {
    announcements: '/soopshtenija',
    activities:'/aktivnosti' ,
    sections: '/sekcii', 
    employees: '/vraboteni',
    herobanner: '/hero',
    documents: '/dokumenti'
  },
  POST: {
      messages: '/messages',
  },
  STORAGE: storageUrl,
};