import React from 'react';
import { Container, Navbar, Nav, Image } from 'react-bootstrap'
import classes from '../Header/header.module.css'
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Navbar className={`bg-navbar border-bot ${classes.header}`}  expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <Image className={`${classes.widthCustom} d-block me-3`} src='../image/logo.png' alt="image" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end align-content-center">
          <Nav className="">
            <Nav.Link as={Link} className="ms-3" to="/za-nas">За Нас</Nav.Link>
            <Nav.Link as={Link} className="ms-3" to="/sekcii">Секции</Nav.Link>
            <Nav.Link as={Link} className="ms-3" to="/aktivnosti">Вон-наставни активности</Nav.Link>
            <Nav.Link className="ms-3" target="_blank">Ученички Парламент</Nav.Link>
            <Nav.Link as={Link} className="ms-3" to="/soopshtenija">Соопштенија</Nav.Link>
            <Nav.Link as={Link} className="ms-3" to="/vraboteni">Вработени</Nav.Link>
            <Nav.Link as={Link} className="ms-3" to="/kontakt">Контакт</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
