import React, {useContext} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Announcementcard from '../../components/AnnouncementCard/Announcementcard'
import { Datacontext } from '../../context/Datacontext'

export default function Sekcii() {

  const {sekcii} = useContext(Datacontext)

  return (
    <Container as="section" fluid className='px-0 bgImageSchool'>
      <Row className='rowWidthCustom py-5 bgImageSchoolInside'>
        <Col xs={12} className=''>
          <h1 className='fw-bold' >Секции</h1>
        </Col>
      </Row>
      <Row className='rowWidthCustom pb-5 bgImageSchoolInside'>
        {sekcii.map(item =>
          <Announcementcard route='sekcii' key={item.id} {...item} />
        )}
      </Row>
    </Container>
  )
}
