import React, {useState, useEffect, createContext} from "react";
import { API } from "../consts/API";

export const Datacontext = createContext();

export const DatacontextProvider = ({ children }) => {
  const [soopshtenija, setAnnouncements] = useState([]);
  const [aktivnosti, setActivities] = useState([]);
  const [sekcii, setSections] = useState([]);

  useEffect(() => {
    fetch(API.dynamicRoute(API.GET.announcements))
    .then(res => res.json())
    .then(data => setAnnouncements([...data.data]))
  },[])
  useEffect(() => {
    fetch(API.dynamicRoute(API.GET.activities))
    .then(res => res.json())
    .then(data => setActivities([...data.data]))
  },[])
  useEffect(() => {
    fetch(API.dynamicRoute(API.GET.sections))
    .then(res => res.json())
    .then(data => setSections([...data.data]))
  },[])

  const DataContextObj = {
    soopshtenija,
    aktivnosti,
    sekcii
  };

  return <Datacontext.Provider value={DataContextObj}>{children}</Datacontext.Provider>;
}  