import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Employeecard from './Employeecard';
import classes from './employees.module.css'
import { API } from '../../consts/API';


const Employees = () => {

  const [employees, setEmployees] = useState()

  useEffect(() => {
    fetch(API.dynamicRoute(API.GET.employees))
    .then(res => res.json())
    .then(data => setEmployees(data))
  }, [])

  return (
    <Container fluid as='section' className={`${classes.emplContainer} bgImageSchool pt-0`}>
      <Row className='rowWidthCustom bgImageSchoolInside pt-5'>
        <Col>
          <h1 className='fw-bold'>Наставен кадар</h1>
        </Col>
      </Row>
      <Row className='rowWidthCustom bgImageSchoolInside'>
        <h2> - Директор</h2>
        <Employeecard key={employees?.director[0].id} {...employees?.director[0]}/>
      </Row>
      <Row className='rowWidthCustom bgImageSchoolInside'>
        <h2> - Педагози</h2>
        {employees?.pedagog.map(el => 
          <Employeecard key={el.id} {...el} />
        )}
      </Row>
      <Row className='rowWidthCustom bgImageSchoolInside'>
        <h2> - Одделенски Наставници</h2>
        {employees?.class_professor.map(el => 
          <Employeecard key={el.id} {...el} />
        )}
      </Row>
      <Row className='rowWidthCustom bgImageSchoolInside'>
        <h2> - Предметни Наставници</h2>
        {employees?.sub_professor.map(el => 
          <Employeecard key={el.id} {...el} />
        )}
      </Row>
      <Row className='rowWidthCustom justify-content-between h-100 bgImageSchoolInside'>
        <Col xs={12} md={6} xl={4} className='p-0 h-100 text-center'>
          <h2> - Специјален едукатор и рехабилитатор </h2>
          <Employeecard fullWidth={true} key={employees?.special_educator[0].id} {...employees?.special_educator[0]}/>
        </Col>
        <Col xs={12} md={6} xl={4} className='p-0 mt-auto text-center'>
          <h2> - Теолог </h2>
          <Employeecard fullWidth={true} key={employees?.teolog[0].id} {...employees?.teolog[0]}/>
        </Col>
        <Col xs={12} md={6} xl={4} className='p-0 mt-auto text-center'>
          <h2> - Библиотекар </h2>
          <Employeecard fullWidth={true} key={employees?.librerian[0].id} {...employees?.librerian[0]}/>
        </Col>        
      </Row>
    </Container>
  );
}

export default Employees;
