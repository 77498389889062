import React, {useContext} from 'react';
import Herobanner from '../../components/Herobanner/Herobanner';
import { Container } from 'react-bootstrap';
import Homepagesections from '../../components/Homepagesections/Homepagesections';
import { Datacontext } from '../../context/Datacontext';

const Home = () => {

  const { soopshtenija, aktivnosti, sekcii } = useContext(Datacontext)

  return (
    <Container fluid className='p-md-4 p-2 bgImageSchool'>
      <Herobanner className='mb-5'/>
      {soopshtenija !== [] && <Homepagesections route='/soopshtenija' title='Соопштенија' api={soopshtenija}/>}
      {aktivnosti !== [] && <Homepagesections route='/sekcii' title='Секции' api={sekcii}/>}
      {sekcii !== []&& <Homepagesections route='/aktivnosti' title='Вон-наставни активности' api={aktivnosti}/>}      
    </Container>
  );
}

export default Home;
