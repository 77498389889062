import React from 'react';
import {Col, Card} from 'react-bootstrap';
import classes from './Homepagecard.module.css';
import { API } from '../../consts/API';
import { Link } from 'react-router-dom';
import dateFormat from '../../Hooks/dateFormat';

const Homepagecard = ({api, route}) => {

  const arr = api?.slice(0, 3)
  return (<>
      {arr && arr?.map(({cover_image, title, sid, id, created_at}) =>
        <Col key={id} as={Link} to={`${route}/${sid}`} xs={12} md={6} lg={4} className={classes.homepageCard}>
          <Card className='border-0'>
            <Card.Img variant='top' src={API.STORAGE+cover_image}/>
            <Card.Body className='p-0 d-flex flex-column justify-content-between'>
              <Card.Title className='px-2'>
                <h5>{title}</h5>
              </Card.Title>
            </Card.Body>
            <Card.Footer className='text-end text-decoration-none text-black'>
              {dateFormat(created_at)}
            </Card.Footer>
          </Card>
        </Col>)}
      </>
  );
}

export default Homepagecard;
