import React, {useEffect, useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap'
import { API } from '../../consts/API';
import dateFormat from '../../Hooks/dateFormat';

const Detailspage = () => {

  const { id } = useParams()

  const [currentItem, setCurrentItem] = useState({})
  const currentLocation = useLocation().pathname

  useEffect(()=>{
    fetch(API.dynamicRoute(currentLocation))
    .then(res => res.json())
    .then(data => setCurrentItem({...data?.data[0]}))
  },[])
  
  const {title, description, gallery} = currentItem

  return (
    
    <Container fluid className='px-0 py-4 bgImageSchool'>
      <Row className='rowWidthCustom bgImageSchoolInside'>
        <Col xs={12} className='p-0 text-center'>
          <h1>
            {currentItem ? title : ''}
          </h1>
        </Col>
        <Col xs={12} className='p-0 py-5 text-center'>
          <p>
            {currentItem ? description : ''}
          </p>
        </Col>
        <Col xs={12} className='p-0'>
          <Row className='mx-0 '>
            {gallery?.map(img =>
            <Col key={img.id} xs={12} md={6} lg={4} className='mb-3 opacity-1'>
             <img src={API.STORAGE+img.img} className='w-100 d-block'/>
            </Col>)}
          </Row>
        </Col>
        <Col xs={12} className="px-0 py-3 text-end text-muted fst-italic">
          <p>
            Објавено на: {dateFormat(currentItem.created_at)}
          </p>
        </Col>
      </Row>
      
    </Container>
  );
}

export default Detailspage;
